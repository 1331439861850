.cookie {
  display: none;
  opacity: 0;
  transition: all 1s ease-out;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $primary-color;
    height: 240px;
    padding: 12px;
    width: 290px;
    border: none;
    color: white;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    z-index: 10000;
    @media (max-width: 575.98px) {
      width: 100%;
      height: 190px;
      bottom: 0px;
      right: 0px;
    }
    @media (max-width: 400.98px) {
      height: 210px;
    }
  }
  a {
    font-size: 1.1rem;
    text-decoration: underline;
    transition: $transition;
    color: #fff;
    &:hover {
      color: #333333;
    }
  }
  button {
    background-color: $button-color;
    transition: $transition;
    &:hover {
      background-color: darken($button-color, 10%);
    }
  }
  &.active {
    display: block;
  }
  &.show {
    opacity: 1;
  }
}
