.countdown {
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  .title {
    letter-spacing: 0.125rem;
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $card-green;
    text-align: center;
    padding-bottom: 2rem;
    @media (max-width: 991.98px) {
      font-size: 2rem;
    }
    @media (max-width: 767.98px) {
      font-size: 1.5rem;
    }
  }

  #countdownEl {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .numbers-container {
      display: flex;

      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      text-transform: uppercase;
      color: #fff;
      width: 150px;
      span {
        display: block;
        font-size: 1.4rem;
        text-align: center;
        min-width: 200px;
      }
      span.numbers {
        display: block;
        text-align: center;
        color: #fff;
        font-size: 2.9rem;
        font-weight: 600;
        text-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
        @media (max-width: 767.98px) {
          font-size: 2rem;
        }
      }
    }
    .first-timer,
    .second-timer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 600.98px) {
      flex-direction: column;
      .first-timer {
        margin-bottom: 2rem;
      }
    }
  }

  #successContent {
    display: none;
    color: #fff;
    font-size: 2rem;
  }
}
