.contact {
  background-color: $background-color-3;
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 10rem;
  color: #fff;
  .content-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    @media (max-width: 1199.98px) {
      margin: auto;
    }
    @media (max-width: 1000.98px) {
      max-width: 600px;
      flex-direction: column;
      align-items: center;
    }
  }

  .logo-part {
    img {
      height: auto;
      width: 170px;

      border-image-slice: 1;
    }
    .logo-text {
      font-size: 1.3rem;
      padding-top: 0.5rem;
      word-spacing: 3px;
      font-family: $font-family-1;
    }
  }

  .kontakt-form {
    max-width: 550px;
    margin: auto;
  }
  .person-content {
    padding: 0 12px;
    min-width: 400px;

    .name {
      font-size: 1.4rem;
      text-align: center;
      padding-bottom: 1rem;
      font-family: $font-family-1;
      color: $primary-color;
    }
  }

  .title {
    text-transform: uppercase;
    color: $primary-color;
    font-size: 1.5rem;
    font-family: $font-family-1;
    text-align: center;
  }

  .sub-text {
    font-size: 1.2rem;
    color: #ffffff;
    padding-bottom: 1rem;
  }
  .link-container {
    text-align: center;
  }
  .main_error {
    color: $red-color;
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 0.4rem;
  }
  .input-container {
    padding-bottom: 0.8rem;
    p {
      color: $primary-color;
      font-family: $font-family-1;
      padding-bottom: 0.5rem;
      font-size: 1.1rem;
    }
    input {
      width: 100%;
      border: none;
      border: 1px solid #333;
      border-bottom: 3px solid #000000;
      background: #333;
      color: #ffffff;
      transition: $transition;
      &::placeholder {
        color: #ffffff;
      }
      &:focus {
        // box-shadow: 0 0 5px rgba($primary-color, 0.8);
        border: unset;
        border-bottom: 3px solid rgba($primary-color, 0.8);
      }
      &.error {
        border: 1px solid $red-color;
        border-bottom: 3px solid $red-color;
      }
    }

    textarea {
      color: #ffffff;
      width: 100%;
      height: 160px;
      border: none;
      padding-left: 0.4rem;
      border: 1px solid #333;
      border-bottom: 3px solid #000000;
      background: #333;
      transition: $transition;
      &::placeholder {
        color: #ffffff;
      }
      &:focus {
        // box-shadow: 0 0 5px rgba($primary-color, 0.8);
        border: unset;
        border-bottom: 3px solid rgba($primary-color, 0.8);
      }
      &.error {
        border: 1px solid $red-color;
        border-bottom: 3px solid $red-color;
      }
    }
  }
  .law-container {
    display: flex;
    padding-top: 1rem;
    font-size: 1rem;
    color: #ffffff;
    a {
      font-size: 1rem;
      color: #ffffff;
      transition: $transition;
      &:hover {
        color: darken($primary-color, 10%);
      }
    }
    small {
      color: $red-color;
      opacity: 0;
    }
    &.error {
      small {
        opacity: 1;
        display: block;
      }
    }
  }
  .social-media-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
    flex-direction: column;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
    .social-unter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2rem;
    }
    .one-social {
      margin: 0 1rem;
      cursor: pointer;
      transition: $transition;
      border-bottom: 2px solid $background-color-3;
      &:hover {
        border-bottom: 2px solid $primary-color;
      }
    }
    img {
      height: 35px;
      width: 35px;
      object-fit: scale-down;
    }
  }
}
