$primary-color: #fe902d; //#f5654b;

$secondary-color: #f8f9fa;
$third-color: #ca020b;
$fourth-color: rgb(196, 113, 16);

$footer-color: #0e0201;
$navbar-color: $primary-color;
$button-color: #993300;
$border-color: lighten(#333, 60%);

// $background-color: #f5f5f5;
$background-color: #fdf5e8;

$dark-color: #0a1726;
$gray-color: #808080;
$light-color: #f4f4f4;
$red-color: #dc3545;
$blue-color: #1a73e8;
$green-color: #28a745;
$orange-color: #ffa500;

$primary-overlay: lighten($primary-color, 20%);

$gray-color-1: #fbfbfb;
$gray-color-2: #ececec;
// $primary-color-15: rgba($primary-color, 0.15);

$background-color-2: rgb(255, 253, 234); // #FFFDEA

$primary-color-hover: darken($primary-color, 10%);

@font-face {
  font-family: 'Nunito';
  src: url('/src/assets/fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('/src/assets/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
}
$font-family: 'Nunito', Arial, Helvetica, sans-serif;
$font-family-1: 'Space Grotesk', sans-serif;
$transition: all 0.4s ease-out;
$main-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
// table

$card-green: $primary-color;

$background-color-1: $background-color;
$background-color-2: $background-color;
$background-color-3: $background-color;
