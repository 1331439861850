.faq {
  padding-top: 3rem;

  min-height: 100vh;
  max-width: 900px;
  margin: auto;
  .one-faq-box {
    padding-top: 2.5rem;
  }
  .title-container {
    background-color: $gray-color;
    color: #fff;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    padding: 0.5rem 12px;
    border-bottom: 4px $primary-color solid;
    font-size: 25px;
    font-weight: 600;
  }
  .faq-content-container {
    background: #fff;
    font-size: 20px;
    padding: 0.75rem 12px;
    box-shadow: $main-box-shadow;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    .faq-link {
      font-size: 20px;
      font-weight: 600;
      transition: $transition;
      &:hover {
        color: $primary-color;
      }
    }
  }
}
