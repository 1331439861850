// $navbar-breakpoint: 991.98px;
// $height-navbar: 90px;
$navbar-breakpoint: 1299px;
$height-navbar: 80px;
$height-navbar-negativ: -80px;
$navbar-breakpoint-scroll: 600px;
.navbar-dummy {
  height: $height-navbar;
  background-color: $navbar-color;
}
.navbar {
  z-index: 100;
  color: #fff;
  background-color: $navbar-color;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-bottom: 2px solid $background-color;
  position: fixed;
  width: 100%;
  transition: $transition;
  .navbar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0rem 12px;
    height: $height-navbar;
  }

  &.navbar-opacity {
    opacity: 0;
    transform: translateY($height-navbar-negativ);
  }

  &.active {
    transform: translateY(0px);
    opacity: 1;
  }

  .display-btn-none {
    display: none;
  }

  .z-nav-pages {
    display: flex;
    padding: 0rem 2rem;
    align-items: center;
  }
  .z-nav-page {
    padding-right: 1.5rem;
  }
  .page-link {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    transition: $transition;
    &:hover {
      color: $gray-color-2;
    }
  }
  .z-burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    cursor: pointer;
    transition: $transition;
    div {
      width: 25px;
      height: 3px;
      background-color: #fff;
      margin: 2.5px;
      transition: $transition;
    }
    &:hover {
      div {
        background-color: #333;
      }
    }
  }
  .z-toggle {
    div {
      background-color: #fff !important;
    }
    .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }

  .navbar-btn {
    display: inline-block;
    width: 140px;
    text-align: center;
  }

  .z-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 3%;
    .earth-logo-img {
      height: 45px;
      width: auto;
      transform: translate(-25px, -7px);
    }
    .logo-text {
      font-size: 20px;
      p {
        line-height: 20px;
      }
      .text-2 {
        transform: translateX(25px);
      }
      .text-3 {
        transform: translateX(35px);
      }
      .first-letter {
        color: $button-color;
        font-weight: 600;
      }
    }
  }

  .dashboard-navbar-drop--new-message-text {
    font-size: 12px;
    display: block;
    padding-top: 4px;
  }
  .dashboard-navbar-drop--first-name {
    font-size: 14px;
  }

  .dashboard-navbar-drop--btn-user {
    min-width: 140px;
    margin-left: 16px;
    @media (max-width: $navbar-breakpoint) {
      width: 200px;
      margin-left: 0px;
    }
  }
  @media (max-width: $navbar-breakpoint) {
    .z-nav-pages {
      background-color: $background-color;
      opacity: 0.99;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15),
        0 8px 8px rgba(0, 0, 0, 0.15);
      display: flex;
      position: absolute;
      right: 0px;
      top: $height-navbar;

      min-width: 300px;
      max-width: 800px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      z-index: 10;
      opacity: 0.99;
      padding-top: 0.9rem;
      margin-top: 1px;
    }
    .z-nav-page {
      opacity: 0;
      padding: 1.2rem 0rem;
      padding-right: 0rem;
    }
    .page-link {
      color: #333;
      &:hover {
        color: $primary-color;
      }
    }
    .z-burger {
      display: flex;
      z-index: 100;
    }
  }
}
@media (max-width: $navbar-breakpoint) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }
}

@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
