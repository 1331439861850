.home-logo-einleitung {
  padding: 2rem 0rem;

  padding-bottom: 8rem;

  .title {
    color: $primary-color;
    text-align: center;
    font-size: 2.9rem;
  }

  .z-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-bottom: 1rem;
    .earth-logo-img {
      height: 100px;
      width: auto;
      transform: translate(-35px, -18px);
    }
    .logo-text {
      font-size: 40px;
      p {
        line-height: 45px;
      }
      .text-2 {
        transform: translateX(50px);
      }
      .text-3 {
        transform: translateX(70px);
      }
      .first-letter {
        color: $button-color;
        font-weight: 600;
      }
    }
  }
  .desc {
    max-width: 750px;
    margin: auto;
    font-size: 1.2rem;
    a {
      font-size: 1.2rem;
    }
  }
  .home-btn {
    font-size: 1.2rem;
  }

  @media (max-width: 567.98px) {
    .title {
      font-size: 2rem;
    }
    .desc {
      font-size: 1.1rem;
      a {
        font-size: 1.1rem;
      }
    }
    .home-btn {
      font-size: 1.1rem;
    }
  }
}

.home-bild-text {
  .klang-img {
    border-radius: 0.6rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
    box-sizing: border-box;
    color: #000;

    height: auto;

    max-width: 100%;

    max-height: 450px;
    object-fit: scale-down;
    text-align: center;
  }
  .home-hr-white {
    background-color: #fff;
  }

  .title {
    text-align: center;
    font-size: 2.5rem;
  }
  .desc {
    font-size: 1.2rem;

    margin: auto;
    max-width: 600px;
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 0rem 12px;

    min-height: 500px;
  }

  .title {
    color: $primary-color;
  }

  @media (max-width: 767.98px) {
    .content {
      min-height: 300px;
    }
    .title {
      font-size: 2rem;
    }
    .desc {
      font-size: 1.1rem;
    }
  }
  @media (max-width: 567.98px) {
    .title {
      font-size: 1.7rem;
    }
    .desc {
      font-size: 1rem;
    }
  }
}

.tippy-part {
  cursor: pointer;

  transition: $transition;
  &:hover {
    color: $primary-color;
  }
}

.tippy-home {
  font-size: 1.1rem;
  width: 0;
  transform: translate(-10px, -10px);
}

.home-next-step-area {
  padding-top: 3rem;
  padding-bottom: 12rem;

  .box {
    background-color: $primary-color;

    max-width: 900px;
    margin: auto;
    padding: 2rem 16px;

    border-radius: 0.6rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
  }
  .text {
    text-align: center;
    font-size: 1.5rem;
    font-family: $font-family-1;
    font-weight: 600;
    padding-bottom: 2rem;
  }
  .home-btn {
    font-size: 1.2rem;
  }

  @media (max-width: 567.98px) {
    .home-btn {
      font-size: 1.1rem;
    }
    .text {
      font-size: 1.1rem;
    }
  }
  .text-link {
    display: inline-block;
    // white-space: pre-line;
    font-weight: 600;
    text-decoration: underline;
    &:hover {
      color: $primary-color;
    }
  }
}
