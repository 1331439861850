.footer {
  background-color: $dark-color;
  padding: 1.5rem 2% 2rem 2%;
  .row {
    @media (max-width: 991.98px) {
      max-width: 600px;
      margin-right: auto;
    }
  }
  .img-part {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @media (max-width: 991.98px) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
    @media (max-width: 575.98px) {
      align-items: center;
      max-width: 300px;
      margin: auto;
    }
  }

  .z-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-bottom: 1rem;
    .earth-logo-img {
      height: 45px;
      width: auto;
      transform: translate(-25px, -7px);
    }
    .logo-text {
      font-size: 20px;
      p {
        line-height: 20px;
      }
      .text-2 {
        transform: translateX(25px);
      }
      .text-3 {
        transform: translateX(35px);
      }
      .first-letter {
        color: $button-color;
        font-weight: 600;
      }
    }
  }

  ul {
    color: #ffffff;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      padding-bottom: 0.15rem;
      a {
        display: block;
        color: #ffffff;
        font-size: 1.1rem;
        transition: all 0.5s ease-out;
        margin: 0;
        padding: 0;
        &:hover {
          color: $border-color;
        }
      }
      @media (max-width: 575.98px) {
        padding-bottom: 1rem;
      }
    }
  }
  .content-part {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @media (max-width: 991.98px) {
      align-items: flex-start;
    }
    @media (max-width: 575.98px) {
      align-items: flex-start;
      max-width: 300px;
      margin-right: auto;
    }
    .title {
      color: $button-color;
      font-size: 1.2rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      @media (max-width: 767.98px) {
        padding-top: 0.6rem;
      }
    }
  }
  .copyright {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #fff;
  }
}
