.ueber-uns {
  padding-bottom: 8rem;
  padding-top: 4rem;
  min-height: 120vh;

  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  h1 {
    // text-align: center;
    color: $primary-color;
    font-size: 3rem;
    font-weight: 600;
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    // text-align: center;
    color: $primary-color;
    font-size: 2.2rem;
    font-weight: 600;
    @media (max-width: 575.98px) {
      font-size: 1.3rem;
    }
  }
  .klang-img {
    border-radius: 0.6rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
    box-sizing: border-box;
    color: #000;

    height: auto;
    max-width: 100%;
    max-height: 500px;
    object-fit: scale-down;
  }
  .desc {
    font-size: 1.2rem;
    @media (max-width: 575.98px) {
      font-size: 1rem;
    }
  }
  .not-big {
    display: none;
  }

  @media (max-width: 991.98px) {
    .not-small {
      display: none;
    }
    .not-big {
      display: block;
    }
  }
}
