.shared-br {
  content: ' ';
  display: block;
  margin: 1em;
}

.shared-input-title {
  font-weight: 600;
  font-size: 1.1rem;
  padding-bottom: 0.25rem;
}

.shared-hr {
  width: 42px;
  height: 4px;
  border-radius: 4px;
  color: $primary-color;
  border: none;
  background-color: $primary-color;
  margin-bottom: 0.4rem;
  &--center {
    margin: auto;
  }
}

.shared-bold {
  font-weight: 600;
}

.shared-not-found {
  min-height: 120vh;
  background-color: $background-color;
}

.shared-pointer {
  cursor: pointer;
}

.shared-primary-color {
  color: $primary-color;
}
