.background-1 {
  background-image: repeating-radial-gradient(
      circle at center center,
      transparent 0px,
      transparent 2px,
      rgb(253, 245, 232) 2px,
      rgb(253, 245, 232) 3px,
      transparent 3px,
      transparent 5px,
      rgb(253, 245, 232) 5px,
      rgb(253, 245, 232) 7px
    ),
    repeating-radial-gradient(
      circle at center center,
      rgb(255, 255, 255) 0px,
      rgb(255, 255, 255) 9px,
      rgb(255, 255, 255) 9px,
      rgb(255, 255, 255) 21px,
      rgb(255, 255, 255) 21px,
      rgb(255, 255, 255) 31px,
      rgb(255, 255, 255) 31px,
      rgb(255, 255, 255) 40px
    );
  background-size: 20px 20px;
}

.background-2 {
  background-image: radial-gradient(
      circle at 28% 90%,
      rgba(254, 210, 171, 0.5) 0%,
      rgba(254, 210, 171, 0.5) 24%,
      transparent 24%,
      transparent 100%
    ),
    radial-gradient(
      circle at 84% 65%,
      rgba(173, 173, 173, 0.03) 0%,
      rgba(173, 173, 173, 0.03) 45%,
      transparent 45%,
      transparent 100%
    ),
    radial-gradient(
      circle at 46% 12%,
      rgba(254, 210, 171, 0.2) 0%,
      rgba(254, 210, 171, 0.2) 22%,
      transparent 22%,
      transparent 100%
    ),
    radial-gradient(
      circle at 26% 14%,
      rgba(119, 119, 119, 0.03) 0%,
      rgba(119, 119, 119, 0.03) 35%,
      transparent 35%,
      transparent 100%
    ),
    radial-gradient(
      circle at 81% 90%,
      rgba(254, 210, 171, 0.5) 0%,
      rgba(254, 210, 171, 0.5) 40%,
      transparent 40%,
      transparent 100%
    ),
    radial-gradient(
      circle at 28% 100%,
      rgba(254, 210, 171, 0.7) 0%,
      rgba(254, 210, 171, 0.7) 56%,
      transparent 56%,
      transparent 100%
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
}

.background-3 {
  background-image: linear-gradient(
      340deg,
      rgba(76, 76, 76, 0.02) 0%,
      rgba(76, 76, 76, 0.02) 34%,
      transparent 34%,
      transparent 67%,
      rgba(142, 142, 142, 0.02) 67%,
      rgba(142, 142, 142, 0.02) 73%,
      rgba(151, 151, 151, 0.02) 73%,
      rgba(151, 151, 151, 0.02) 100%
    ),
    linear-gradient(
      320deg,
      rgba(145, 145, 145, 0.02) 0%,
      rgba(145, 145, 145, 0.02) 10%,
      transparent 10%,
      transparent 72%,
      rgba(35, 35, 35, 0.02) 72%,
      rgba(35, 35, 35, 0.02) 76%,
      rgba(69, 69, 69, 0.02) 76%,
      rgba(69, 69, 69, 0.02) 100%
    ),
    linear-gradient(
      268deg,
      rgba(128, 128, 128, 0.02) 0%,
      rgba(128, 128, 128, 0.02) 5%,
      transparent 5%,
      transparent 76%,
      rgba(78, 78, 78, 0.02) 76%,
      rgba(78, 78, 78, 0.02) 83%,
      rgba(224, 224, 224, 0.02) 83%,
      rgba(224, 224, 224, 0.02) 100%
    ),
    linear-gradient(
      198deg,
      rgba(25, 25, 25, 0.02) 0%,
      rgba(25, 25, 25, 0.02) 36%,
      transparent 36%,
      transparent 85%,
      rgba(180, 180, 180, 0.02) 85%,
      rgba(180, 180, 180, 0.02) 99%,
      rgba(123, 123, 123, 0.02) 99%,
      rgba(123, 123, 123, 0.02) 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.background-4 {
  background-image: radial-gradient(
      circle at 28% 90%,
      rgba(113, 113, 113, 0.03) 0%,
      rgba(113, 113, 113, 0.03) 24%,
      transparent 24%,
      transparent 100%
    ),
    radial-gradient(
      circle at 84% 65%,
      rgba(173, 173, 173, 0.03) 0%,
      rgba(173, 173, 173, 0.03) 45%,
      transparent 45%,
      transparent 100%
    ),
    radial-gradient(
      circle at 46% 12%,
      rgba(141, 141, 141, 0.03) 0%,
      rgba(141, 141, 141, 0.03) 22%,
      transparent 22%,
      transparent 100%
    ),
    radial-gradient(
      circle at 26% 14%,
      rgba(119, 119, 119, 0.03) 0%,
      rgba(119, 119, 119, 0.03) 35%,
      transparent 35%,
      transparent 100%
    ),
    radial-gradient(
      circle at 81% 90%,
      rgba(42, 42, 42, 0.03) 0%,
      rgba(42, 42, 42, 0.03) 40%,
      transparent 40%,
      transparent 100%
    ),
    radial-gradient(
      circle at 28% 100%,
      rgba(234, 234, 234, 0.03) 0%,
      rgba(234, 234, 234, 0.03) 56%,
      transparent 56%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}
