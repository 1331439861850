.theme-flex-btn-container-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin: 0 0.25rem;
  }

  @media (max-width: 575.98px) {
    flex-direction: column;
    a {
      margin-bottom: 1.5rem;
    }
  }
}

.theme-link {
  font-weight: 600;
  transition: $transition;
  &:hover {
    color: $primary-color;
  }
}
