//  Showcase

$showcase-height: 600px;
$showcase-height-2: 500px;
$showcase-height-3: 400px;
$showcase-height-4: 380px;
$showcase-unterseite: 400px;
.showcase {
  width: 100%;
  height: $showcase-height;
  position: relative;

  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.2);
  }

  &.showcase_unterseite {
    height: $showcase-unterseite !important;
    .overlay {
      height: $showcase-unterseite !important;
    }
    img {
      height: $showcase-unterseite !important;
    }
    .title {
      margin-top: 120px !important;
    }
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 12px;
    @media (max-width: 991.98px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  .btn-container {
    width: 750px;
    max-width: 100%;
    @media (max-width: 991.98px) {
      padding-top: 2rem;
    }
  }
  .title {
    color: #ffffff;

    text-align: center;
    width: 750px;
    max-width: 100%;
    .h2-fett {
      font-size: 3.2rem;
      font-weight: 600;
    }
    .h2-klein {
      font-size: 2.25rem;
    }
    @media (max-width: 991.98px) {
      .h2-fett {
        font-size: 2.5rem;
      }
    }
    @media (max-width: 767.98px) {
      .h2-fett {
        font-size: 2.2rem;
      }
      .h2-klein {
        font-size: 1.5rem;
      }
    }
    @media (max-width: 575.98px) {
      .h2-fett {
        font-size: 2rem;
      }
      .h2-klein {
        font-size: 1.2rem;
      }
    }
  }

  a.btn {
    padding: 1rem 2rem;
    font-size: 1.3rem;
    @media (max-width: 767.98px) {
      padding: 0.75rem 1.5rem;
      font-size: 1.2rem;
    }
    @media (max-width: 575.98px) {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
  @media (max-width: 991.98px) {
    height: $showcase-height-2;
  }
  @media (max-width: 767.98px) {
    height: $showcase-height-3;
  }

  @media (max-width: 575.98px) {
    height: $showcase-height-4;
  }
}
